<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="uploadDialogFlag"
      width="600px"
      :before-close="handleClose"
    >
      <el-form label-position="right" label-width="100px" v-model="uploadForm">
        <el-form-item label="目标平台" required>
          <el-radio-group
            class="platform-radio"
            size="medium"
            v-model="uploadForm.platform"
            @change="changePlatform"
          >
            <el-radio label="2" border><span class="kaituanyi">开团易</span></el-radio>
            <el-radio label="1" border><span class="kuaituantuan">快团团</span></el-radio>
            <el-radio label="3" border><span class="qunjielong">群接龙</span></el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="授权渠道" required>
          <el-select
            v-model="uploadForm.shopIds"
            placeholder="请选择授权渠道"
            multiple
            clearable
            @clear="changeChannel"
            @change="changeChannel"
          >
            <el-option
              v-for="item in shops"
              :key="item.id"
              :label="item.name"
              :value="item.id"
              :disabled="item.authStatus != 1"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="dialog-footer" slot="footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="uploadIt" :loading="uploading"
          >上传</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { uploadGoods } from "@/api/good";
import { getMall } from "@/api/shop";

export default {
  data() {
    return {
      title: "上传商品到电商平台",
      spuList: [],
      uploadDialogFlag: false,
      uploadForm: {
        qunjielong: {},
      },
      shops: [],
      uploading: false,
      successCallback: null,
    };
  },
  methods: {
    open(spuList, func) {
      this.spuList = spuList;
      this.uploadDialogFlag = true;
      this.successCallback = func;
      this.title = "上传商品到电商平台（" + spuList.length + "件）";
    },
    changePlatform(platform) {
      this.shops = [];
      this.uploadForm.shopIds = null;
      const param = {
        platform: platform,
        pageNo: 1,
        pageSize: 200,
      };
      const that = this;
      getMall(param).then((res) => {
        if (res.code === 200) {
          that.shops = res.data.rows;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    changeChannel() {},
    uploadIt() {
      const param = {
        spuIds: this.spuList.map((s) => s.id),
        upload: this.uploadForm,
      };
      const that = this;
      that.uploading = true;
      uploadGoods(param)
        .then((res) => {
          that.uploading = false;
          if (res.code === 200) {
            that.successCallback && that.successCallback();
            this.handleClose();
            this.$message.success(res.message);
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {
          that.uploading = false;
        });
    },
    handleClose() {
      this.uploadDialogFlag = false;
    },
  },
};
</script>
<style scoped>
.platform-radio .el-radio__input {
  z-index: -1;
}
.el-radio__label{
  padding-left:30px;
  display: inline-block;
}
.kuaituantuan {
  background: url(../../../public/img/ikuaituantuan.png) no-repeat;
  background-size: 16px 16px;
  padding-left: 20px;
  background-position: 0px 0px;
}
.qunjielong {
  background: url(../../../public/img/iqunjielong.png) no-repeat;
  background-size: 16px 16px;
   padding-left: 20px;
  background-position: 0px 0px;
}
.kaituanyi {
  background: url(../../../public/img/kty_logo_gaitubao_20x20.png) no-repeat;
  background-size: 16px 16px;
  padding-left: 20px;
  background-position: 0px 0px;
}
</style>
