<template>
  <div>
    <el-drawer
      :title="'完善商品【' + spu.name + '】在各渠道的资料'"
      size="50%"
      custom-class="extend-drawer"
      :visible.sync="showDrawerFlag"
      :before-close="handleClose"
    >

      <el-tabs v-model="platform" @tab-click="handleClick" type="card">
        <el-tab-pane label="快团团资料" name="1"></el-tab-pane>
        <el-tab-pane label="开团易资料" name="2"></el-tab-pane>
        <el-tab-pane label="群接龙资料" name="3"></el-tab-pane>
      </el-tabs>

      <el-form label-position="right" label-width="150px" v-model="uploadForm" class="info-form">
        <el-form-item label="上传渠道" required>
          <el-select
            v-model="uploadForm.shopId"
            placeholder="请选择上传渠道"
            clearable
            @clear="clearChannel"
            @change="changeChannel"
          >
            <el-option
              v-for="item in shops"
              :key="item.id"
              :label="item.name"
              :value="item.id"
              :disabled="item.authStatus != 1"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <!-- 开团易属性 -->
        <!-- <el-form-item label="商品编码" v-if="platform == '2'">
          <el-checkbox v-model="uploadForm.kaituanyi.withoutCode"
            >只复制商品，不复制商品编码</el-checkbox
          >
        </el-form-item> -->

        <!-- 快团团属性 -->
        <el-form-item label="限购数量" v-if="platform == '1'">
          <el-input-number
            :min="0"
            :max="999"
            :step="1"
            :precision="0"
            :step-strictly="true"
            v-model="uploadForm.kuaituantuan.limitBuy"
          >
          </el-input-number>
        </el-form-item>

        <el-form-item label="库存类型" v-if="platform == '1'">
          <el-select
            v-model="uploadForm.kuaituantuan.quantityType"
            placeholder="请选择库存类型"
            clearable
          >
            <el-option label="普通库存" value="0"></el-option>
            <el-option label="无限库存" value="1"></el-option>
          </el-select>
        </el-form-item>

        <!-- // 暂不支持需要跟随账号变化的商品属性
            <el-form-item label="商品分类" v-if="platform == '3'">
          <el-select
            v-model="uploadForm.qunjielong.categoryId"
            filterable
            remote
            reserve-keyword
            :remote-method="listQunjielongCategory"
            :loading="loadingRemote"
            @focus="() => listQunjielongCategory()"
          >
            <el-option
              v-for="item in categories"
              :key="item.goodsCategoryId"
              :label="item.goodsCategoryName"
              :value="item.goodsCategoryId"
            >
            </el-option>
          </el-select>
        </el-form-item> -->

        <!-- 群接龙属性 -->
        <el-form-item label="自定义标签" v-if="platform == '3'">
          <el-select
            v-model="uploadForm.qunjielong.goodsLabelList"
            multiple
            filterable
            allow-create
            default-first-option
            placeholder="请输入自定义标签"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="佣金千分比" v-if="platform == '3'">
          <el-input-number
            :min="0"
            :max="999"
            :step="1"
            :precision="0"
            :step-strictly="true"
            v-model="uploadForm.qunjielong.commissionPercent"
          >
          </el-input-number>
        </el-form-item>
      </el-form>

      <div class="footer-bottom">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="saveIt">仅保存</el-button>
        <el-button type="primary" @click="saveThenUpload" :loading="uploading"
          >保存且上传</el-button
        >
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { uploadGoods, listQunjielongCategory, completeGoods } from "@/api/good";
import { getMall } from "@/api/shop";
export default {
  data() {
    return {
      platform: "1",
      spu: {},
      shops: [],
      callbackWhenClose: false,
      showDrawerFlag: false,
      success: null,
      uploadForm: {
        shopId: null,
        qunjielong: {},
        kuaituantuan: {},
        kaituanyi: {},
      },
      options: [],
      loadingRemote: false,
      categories: [],
      uploading: false,
    };
  },
  methods: {
    open(platform, spu, func) {
      this.platform = platform;
      this.spu = spu;
      this.success = func;
      this.showDrawerFlag = true;
      this.uploadForm.shopId = null;
      this.uploadForm.qunjielong = this.spu.extendAttr.qunjielong || {};
      this.uploadForm.kuaituantuan = this.spu.extendAttr.kuaituantuan || {};
      this.changePlatform(this.platform);
    },
    handleClose() {
      this.showDrawerFlag = false;
      if (this.callbackWhenClose && this.success) this.success();
    },
    handleClick() {
      this.uploadForm.shopId = null;
      this.changePlatform(this.platform);
    },
    saveIt(upload) {
      const param = {
        spuId: this.spu.id,
        platform: this.platform,
        extendAttr: this.uploadForm,
      };
      const that = this;
      completeGoods(param).then((res) => {
        if (res.code === 200) {
          that.callbackWhenClose = true;
          this.$message.success("保存成功");
          if (typeof upload == "function") upload();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    saveThenUpload() {
      this.saveIt(this.uploadIt);
    },
    uploadIt() {
      const param = {
        spuIds: [this.spu.id],
        upload: {
          platform: this.platform,
          shopIds: [this.uploadForm.shopId],
        },
      };
      const that = this;
      that.uploading = true;
      uploadGoods(param)
        .then((res) => {
          that.uploading = false;
          if (res.code === 200) {
            that.callbackWhenClose = true;
            this.$message.success("上传成功");
          } else {
            this.$message.error("【上传失败】" + res.message);
          }
        })
        .catch((err) => {
          that.uploading = false;
        });
    },
    clearChannel() {
      this.uploadForm.shopId = null;
      this.changeChannel();
    },
    changeChannel() {
      this.uploadForm.qunjielong.categoryId = null;
    },
    changePlatform(platform) {
      this.shops = [];
      this.uploadForm.shopId = null;
      const param = {
        platform: platform,
        pageNo: 1,
        pageSize: 200,
      };
      const that = this;
      getMall(param).then((res) => {
        if (res.code === 200) {
          that.shops = res.data.rows;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    listQunjielongCategory(val) {
      if (this.uploadForm.shopId == null) return;
      const param = {
        shopId: this.uploadForm.shopId,
        name: val,
      };
      const that = this;
      that.loadingRemote = true;
      listQunjielongCategory(param).then((res) => {
        that.loadingRemote = false;
        if (res.code === 200) {
          that.categories = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
};
</script>
<style scoped>
.info-form {
    margin-top: 20px;
}
.extend-drawer {
  padding: 20px 40px 20px 50px;
}
.footer-bottom {
  position: absolute;
  bottom: 40px;
  padding-left: 150px;
}
</style>